import React from 'react';
import ROICalculator from './ROICalculator';

function App() {
    return (
        <div className="App">
            <ROICalculator />
        </div>
    );
}

export default App;
